/* Piilota vierityspalkit, mutta salli vieritys */
html {
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
}

html::-webkit-scrollbar {
  display: none; /* Chrome, Safari */
}

/* TableComponent.js */
.myCustomTable tr td {
  border-bottom: 1px solid #E07A5F; /* Terra Cotta väri oranssiin viivaan */
}

.myCustomTable thead th {
  background-color: #679688; /* Muted Sage Green Dark väri otsikolle */
  color: white; /* Valkoinen tekstiväri kontrastin vuoksi */
  white-space: nowrap; /* Estä tekstiä menemästä useammalle riville */
  overflow: hidden;
  text-overflow: ellipsis;
}

.myCustomTable td, .myCustomTable th {
  white-space: nowrap; /* Estä tekstiä menemästä useammalle riville */
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Mukauta vaakasuuntaista vierityspalkkia */
.scrollWrapper {
  overflow-x: auto; /* Ota vaakasuuntainen vieritys käyttöön */
  max-width: 100%; /* Aseta maksimileveys */
  position: relative; /* Aseta suhteelliseksi, jotta voimme sijoittaa vierityspalkin */
}

/* Mukauta vaakasuuntaisen vierityspalkin ulkonäköä (vain WebKit-pohjaisille selaimille) */
.scrollWrapper::-webkit-scrollbar {
  height: 8px; /* Vierityspalkin korkeus */
  background-color: #F4F1DE; /* Vierityspalkin taustaväri */
  border-radius: 4px; /* Pyöristetyt kulmat */
}

.scrollWrapper::-webkit-scrollbar-thumb {
  background-color: #E07A5F; /* Vierityspalkin "nupin" väri */
  border-radius: 4px; /* Pyöristetyt kulmat nupille */
}

/* Firefox-selaimelle */
.scrollWrapper {
  scrollbar-width: thin;
  scrollbar-color: #E07A5F #F4F1DE;
}